<template>
  <b-navbar
    toggleable="lg"
    type="dark"
    variant="primary"
    class="top-header-container"
  >
    <b-navbar-brand>MISTY ORACLE ADMIN</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item
          @click="$router.push({ name: 'UsersList' }).catch(() => {})"
          >{{ $t("messages.users") }}</b-nav-item
        >
        <b-nav-item
          @click="$router.push({ name: 'AgendamientosAdmin' }).catch(() => {})"
          >{{ $t("nav.appointments") }}</b-nav-item
        >
        <b-nav-item
          @click="$router.push({ name: 'ArticulosAdmin' }).catch(() => {})"
          >{{ $t("nav.articles") }}</b-nav-item
        >
        <b-nav-item
          @click="$router.push({ name: 'NewsLetterAdmin' }).catch(() => {})"
          >{{ $t("nav.newsletter") }}</b-nav-item
        >
        <!-- <b-nav-item
          @click="$router.push({ name: 'CategoriasList' }).catch(() => {})"
          >Categorias</b-nav-item
        >
        <b-nav-item
          @click="$router.push({ name: 'SubcategoriasList' }).catch(() => {})"
          >SubCategorias</b-nav-item
        >
        <b-nav-item
          @click="$router.push({ name: 'ProductosList' }).catch(() => {})"
          >Productos</b-nav-item
        >
        <b-nav-item
          @click="$router.push({ name: 'PresentacionesList' }).catch(() => {})"
          >Presentaciones</b-nav-item
        > -->
        <!-- <b-nav-item @click="$router.push({ name: 'CalendarioGenerator' }).catch(() => {})">Calendario</b-nav-item>
        <b-nav-item @click="$router.push({ name: 'CalendarioDeTrabajo' }).catch(() => {})">Calendario de trabajo</b-nav-item>
        <b-nav-item @click="$router.push({ name: 'PedidosAdmin' }).catch(() => {})">Pedidos</b-nav-item>
        <b-nav-item @click="$router.push({ name: 'MapaDePedidos' }).catch(() => {})">Mapa de Pedidos</b-nav-item> -->
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em>{{ getUserData.nombres }} {{ getUserData.apellidos }}</em>
          </template>
          <b-dropdown-item @click="$router.push({ name: 'Home' })"
            >Sitio Público</b-dropdown-item
          >
          <b-dropdown-item @click="$router.push({ name: 'Profile' })"
            >Profile</b-dropdown-item
          >
          <b-dropdown-item-button @click="setlogout"
            >Salir</b-dropdown-item-button
          >
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "HeaderTopAdmin",
  methods: {
    ...mapMutations(["logout"]),
    setlogout() {
      this.logout();
      this.$router.push({ name: "Home" });
    },
  },
  computed: {
    ...mapGetters(["getUserData"]),
  },
};
</script>

<style lang="scss" scoped>
/* top cabecera */
.top-header-container {
  width: 100%;
}
</style>
