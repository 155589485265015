<template>
  <div class="main-container">
    <header-top-admin />
    <router-view></router-view>
  </div>
</template>

<script>
import HeaderTopAdmin from "@/components/AdminComponents/HeaderTopAdmin";
export default {
  name: "mainAdminComponent",
  components: {
    "header-top-admin": HeaderTopAdmin,
  },  
};
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
  flex-wrap: wrap;
}
</style>
